(function () {
  'use strict';

  angular
  .module('neo.home.ajuntaments.ajuntament.informacio.people')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.ajuntaments.ajuntament.informaciopeople', {
      url: '/people',
      templateUrl: 'home/ajuntaments/ajuntament/informacio/people/people.tpl.html',
      controller: 'AjuntamentPeopleCtrl',
      controllerAs: 'vm'
    });
  }
}());
